<template>
<div class="card col-md-12 p-3 mb-10" style="display:block;">
    <div style="display:inline-block;" class="col-md-6">
      <span class="px-8" style="font-weight: 600;color: #009EF7;">Semester</span>
      <el-select
          class="form-select-solid w-100 px-5"
          placeholder="Select semester"
          v-model="semesterId"
          @change="updateClasses"
      >
        <el-option 
          v-for="semester in semesters" 
          :label="semester.name+'('+semester.code+') : '+moment(semester.start_date).format('YYYY.MM.DD')+'~'+moment(semester.end_date).format('YYYY.MM.DD')" 
          :value="semester.id"
          :key="semester">
          {{semester.name+'('+semester.code+') : '+moment(semester.start_date).format('YYYY.MM.DD')+'~'+moment(semester.end_date).format('YYYY.MM.DD')}}
        </el-option>
      </el-select>
    </div>
    <div style="display:inline-block;" class="col-md-6">
      <span class="px-8" style="font-weight: 600;color: #009EF7;">Subject</span>
      <el-select
          class="form-select-solid w-100 px-5"
          placeholder="Select subject"
          v-model="subjectId"
          filterable
          @change="changeSubject"
      >
        <el-option v-for="subject in subjects" 
          :label="subject.code"   
          :value="subject.id" 
          :key="subject.code">
          {{subject.code}}
        </el-option>
      </el-select>
    </div>
  </div>
<div class="d-flex flex-stack" style="padding-bottom:10px;">
  <div class="page-title d-flex align-items-center me-3 flex-wrap mb-5 mb-lg-0 lh-1">
    <a href="#"
        data-bs-toggle="modal"
        data-bs-target="#modal_update_attendance"
        id="update_attendance"
        style="display:none;"/>
    <a @click="openUpdateModal('')"
        class="btn btn-primary"
        id="update_attendance_btn"
        style="margin-left:10px;">
        Manage attendance
    </a>
  </div>
  <div class="d-flex align-items-center py-1">
      <el-button type="primary" plain style="margin-right:5px;" @click="setCurrentDateRange()">Today</el-button>
      <el-button-group>
        <el-button type="primary" icon="el-icon-arrow-left" @click="setPrevWeekDateRange()" style="color:white;"></el-button>
        <el-button type="primary" icon="el-icon-arrow-right" @click="setNextWeekDateRange()" style="color:white;"></el-button>
      </el-button-group>
  </div>
</div>
<el-table
    :data="attendanceData.value.filter(data => !attendSearch || data.name.toLowerCase().includes(attendSearch.toLowerCase()))"
    style="width: 100%"
    :header-cell-style="{textAlign: 'center'}"
    :header-cell-class-name="getCellClass"
    :cell-class-name="getCellClass">
    <el-table-column
      prop="name"
      label="Name"
      width="400"
      align="left">
      <template #header>
        Name
        <el-input
          v-model="attendSearch"
          size="mini"
          placeholder="Type to search">
        </el-input>
      </template>
    </el-table-column>
    <el-table-column
      prop="date1"
      :label="dateRange[0]"
      align="center">
      <template #header>      
        <p style="margin-bottom: 2px;">SUN</p>
        <a href="#"
            v-if="classDays.includes('0')"
            @click="openUpdateModal(dateRange[0])">
          {{dateRange[0]}}
          ({{attendanceData.value.filter(data => attend_statuses.includes(data.date1.status)).length}})
        </a>
        <span v-else>{{dateRange[0]}}</span>
      </template>
      <template v-slot="scope" >
        <el-button
            size="mini"
            v-if="classDays.includes('0')"
            v-bind:class="getAttendanceButtonClass(scope.row, 'date1')"
            @click="changeAttendType(scope.$index, scope.row, dateRange[0], 'date1')">
            {{scope.row.date1.status}}
        </el-button>
      </template>
    </el-table-column>
    <el-table-column
      prop="date2"
      :label="dateRange[1]"
      align="center">
      <template #header>
        <p style="margin-bottom: 2px;">MON</p>
        <a href="#"
            v-if="classDays.includes('1')"
            @click="openUpdateModal(dateRange[1])">
          {{dateRange[1]}}
          ({{attendanceData.value.filter(data => attend_statuses.includes(data.date2.status)).length}})
        </a>
        <span v-else>{{dateRange[1]}}</span>
      </template>
      <template v-slot="scope">
        <el-button
            size="mini"
            v-if="classDays.includes('1')"
            v-bind:class="getAttendanceButtonClass(scope.row, 'date2')"
            @click="changeAttendType(scope.$index, scope.row, dateRange[1], 'date2')">
            {{scope.row.date2.status}}
        </el-button>
      </template>
    </el-table-column>
    <el-table-column
      prop="date3"
      :label="dateRange[2]"
      align="center">
      <template #header>
        <p style="margin-bottom: 2px;">TUE</p>
        <a href="#" v-if="classDays.includes('2')"
            @click="openUpdateModal(dateRange[2])">
          {{dateRange[2]}}
          ({{attendanceData.value.filter(data => attend_statuses.includes(data.date3.status)).length}})
        </a>
        <span v-else>{{dateRange[2]}}</span>
      </template>
      <template v-slot="scope">
        <el-button
            size="mini"
            v-if="classDays.includes('2')"
            v-bind:class="getAttendanceButtonClass(scope.row, 'date3')"
            @click="changeAttendType(scope.$index, scope.row, dateRange[2], 'date3')">
            {{scope.row.date3.status}}
        </el-button>
      </template>
    </el-table-column>
    <el-table-column
      prop="date4"
      :label="dateRange[3]"
      align="center">
      <template #header>
        <p style="margin-bottom: 2px;">WED</p>
        <a href="#" v-if="classDays.includes('3')"
            @click="openUpdateModal(dateRange[3])">
          {{dateRange[3]}}
          ({{attendanceData.value.filter(data => attend_statuses.includes(data.date4.status)).length}})
        </a>
        <span v-else>{{dateRange[3]}}</span>
      </template>
      <template v-slot="scope">
        <el-button
            size="mini"
            v-if="classDays.includes('3')"
            v-bind:class="getAttendanceButtonClass(scope.row, 'date4')"
            @click="changeAttendType(scope.$index, scope.row, dateRange[3], 'date4')">
            {{scope.row.date4.status}}
        </el-button>
      </template>
    </el-table-column>
    <el-table-column
      prop="date5"
      :label="dateRange[4]"
      align="center">
      <template #header>
        <p style="margin-bottom: 2px;">THU</p>
        <a href="#" v-if="classDays.includes('4')"
            @click="openUpdateModal(dateRange[4])">
          {{dateRange[4]}}
          ({{attendanceData.value.filter(data => attend_statuses.includes(data.date5.status)).length}})
        </a>
        <span v-else>{{dateRange[4]}}</span>
      </template>
      <template v-slot="scope">
        <el-button
            size="mini"
            v-if="classDays.includes('4')"
            v-bind:class="getAttendanceButtonClass(scope.row, 'date5')"
            @click="changeAttendType(scope.$index, scope.row, dateRange[4], 'date5')">
            {{scope.row.date5.status}}
        </el-button>
      </template>
    </el-table-column>
    <el-table-column
      prop="date6"
      :label="dateRange[5]"
      align="center">
      <template #header>
        <p style="margin-bottom: 2px;">FRI</p>
        <a href="#" v-if="classDays.includes('5')"
            @click="openUpdateModal(dateRange[5])">
          {{dateRange[5]}}
          ({{attendanceData.value.filter(data => attend_statuses.includes(data.date6.status)).length}})
        </a>
        <span v-else>{{dateRange[5]}}</span>
      </template>
      <template v-slot="scope">
        <el-button
            size="mini"
            v-if="classDays.includes('5')"
            v-bind:class="getAttendanceButtonClass(scope.row, 'date6')"
            @click="changeAttendType(scope.$index, scope.row, dateRange[5], 'date6')">
            {{scope.row.date6.status}}
        </el-button>
      </template>
    </el-table-column>
    <el-table-column
      prop="date7"
      :label="dateRange[6]"
      align="center">
      <template #header>
        <p style="margin-bottom: 2px;">SAT</p>
        <a href="#" v-if="classDays.includes('6')"
            @click="openUpdateModal(dateRange[6])">
          {{dateRange[6]}}
          ({{attendanceData.value.filter(data => attend_statuses.includes(data.date7.status)).length}})
        </a>
        <span v-else>{{dateRange[6]}}</span>
      </template>
      <template v-slot="scope">
        <el-button
            size="mini"            
            v-if="classDays.includes('6')"
            v-bind:class="getAttendanceButtonClass(scope.row, 'date7')"
            @click="changeAttendType(scope.$index, scope.row, dateRange[6], 'date7')">
            {{scope.row.date7.status}}
        </el-button>
      </template>
    </el-table-column>
  </el-table>

  <UpdateAttendance
    v-bind:targetDate="targetDate"
    v-bind:students="students"
    v-bind:subjectId="subjectId"
    v-bind:getAttendance="getAttendance">
  </UpdateAttendance>
</template>


<script lang="ts"> 
import { defineComponent, onBeforeMount, onMounted, reactive, ref } from "vue";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import UpdateAttendance from "@/views/academics/modals/UpdateAttendanceModal.vue";
import moment from 'moment'
import ApiService from "@/core/services/ApiService";
import _ from "lodash";

export class AttendanceData{
    name: string;
    student_id : number;
    date1 : { id: number | null, status: string };
    date2 : { id: number | null, status: string };
    date3 : { id: number | null, status: string };
    date4 : { id: number | null, status: string };
    date5 : { id: number | null, status: string };
    date6 : { id: number | null, status: string };
    date7 : { id: number | null, status: string };

    constructor(){
      this.date1 = {id: null, status :'Select'};
      this.date2 = {id: null, status :'Select'};
      this.date3 = {id: null, status :'Select'};
      this.date4 = {id: null, status :'Select'};
      this.date5 = {id: null, status :'Select'};
      this.date6 = {id: null, status :'Select'};
      this.date7 = {id: null, status :'Select'};
    }
}

export default defineComponent({
  name: "attendance",
  components: {
    UpdateAttendance
  },
  methods:{
  },
  setup() {
    const targetDate = ref(null);
    const dateRange = reactive<any>([]);
    const semesters = ref([] as any);
    const subjects = ref([] as any);
    const semesterId = ref(0 as any);
    const subjectId = ref(0 as any);
    const attendSearch = ref('');
    const students= ref<any>([]);
    const attendanceData = reactive<any>([]);
    const classDays = ref('');

    attendanceData.value = [];
    
    const getSubjects = async() => {
        subjects.value = [];
        await new Promise<void>((resolve, reject) => {   
            ApiService.setHeader(); 
            ApiService.get("class/"+semesterId.value)
                .then(async({ data }) => {
                  data.data.forEach(program => {
                    program.levels.forEach(level => {
                      level.sessions.forEach(session => {
                        session.subjects.forEach(subject =>{
                          subjects.value.push(subject);
                        })
                      })
                    })
                  });

                  subjectId.value = subjects.value[0].id;
                  setClassDays();
                  resolve(data.data);
                })
                .catch((error) => {
                    reject(new Error ('getClasses error => '+error));
                });
        });
    }

    const getStudents = () : any => {
      return new Promise((resolve, reject) => {    
            ApiService.setHeader();        
            ApiService.get("class-student/students/"+subjectId.value)
                .then(({ data }) => {
                  attendanceData.value = [];
                  students.value=[];                  
                  data.data.forEach(student => {
                      let attendance = new AttendanceData();
                      let studentName = student.student.first_name + " " + student.student.last_name+"("+student.student.student_id+")";
                      attendance.student_id = student.student.id;
                      attendance.name = studentName;
                      attendanceData.value.push(attendance);
                      students.value.push({student_id : student.student.id, name : studentName})
                  });

                  resolve(data.data);
                })
                .catch((error) => {
                    reject(new Error ('getSemesters error => '+error));
                });
        });
    }

    const resetAttendance = () => {
      attendanceData.value.map(attendance => {
        attendance.date1 = {id: null, status :'Select'};
        attendance.date2 = {id: null, status :'Select'};
        attendance.date3 = {id: null, status :'Select'};
        attendance.date4 = {id: null, status :'Select'};
        attendance.date5 = {id: null, status :'Select'};
        attendance.date6 = {id: null, status :'Select'};
        attendance.date7 = {id: null, status :'Select'};
      });
    }

    const getAttendance = async() =>{
        let startDate = new Date(dateRange[0]);
        let endDate = new Date(dateRange[6]);
        endDate.setHours(23,59,59,0)
        ApiService.setHeader();
        return ApiService.get("attendance/"+subjectId.value+"/"+startDate+"/"+endDate)
                .then(({ data }) => {
                    let attendances = _.groupBy(data.data, data => data.student_id);
                    for (const [key, value] of Object.entries(attendances)) {
                        let attendanceIndex = attendanceData.value.findIndex(x=> x.student_id == +key);
                        if(attendanceIndex>=0){
                          for(const sAttendance of value){
                              let attendanceDateString = moment(sAttendance.attendance_date).utc().format('M/DD/YYYY');
                              let attData = { id: sAttendance.id , status:sAttendance.attendance_status};
                            switch(attendanceDateString){
                                case dateRange[0] :
                                    attendanceData.value[attendanceIndex].date1 = attData;
                                    break;
                                case dateRange[1] :
                                    attendanceData.value[attendanceIndex].date2 = attData;
                                    break;
                                case dateRange[2] :
                                    attendanceData.value[attendanceIndex].date3 = attData;
                                    break;
                                case dateRange[3] :
                                    attendanceData.value[attendanceIndex].date4 = attData;
                                    break;
                                case dateRange[4] :
                                    attendanceData.value[attendanceIndex].date5 = attData;
                                    break;
                                case dateRange[5] :
                                    attendanceData.value[attendanceIndex].date6 = attData;
                                    break;
                                case dateRange[6] :
                                    attendanceData.value[attendanceIndex].date7 = attData;
                                    break;
                            }
                          }
                        }
                    };
                })
                .catch((error) => {
                    console.log('getSemesters error => '+error);
                });
    }

    const changeSubject = async() => {
        setClassDays();
        await getStudents().then(async() =>{
          await getAttendance();
        })
    }

    const setClassDays = () =>{
      classDays.value = subjects.value.filter(x=>x.id == subjectId.value)[0]?.class_days.split(';');
    }

    function setDateRange(date){
      var startOfWeek = moment(date).startOf('week').toDate();
      var endOfWeek   = moment(date).endOf('week').toDate();
      emptyArray(dateRange);
      for (var d = startOfWeek; d <= endOfWeek; d.setDate(d.getDate() + 1)) {
          dateRange.push(moment(d).format('M/DD/YYYY'));
      }
    }

    const setPrevWeekDateRange = async() => {
      var prevWeek = moment(dateRange[0]).toDate();
      setDateRange(prevWeek.setDate(prevWeek.getDate()-1));
      resetAttendance();
      await getAttendance();
    }

    const setNextWeekDateRange = async() => {
      var nextWeek = moment(dateRange[6]).toDate();
      setDateRange(nextWeek.setDate(nextWeek.getDate()+1));
      resetAttendance();
      await getAttendance();
    }

    const setCurrentDateRange = async() => {
      setDateRange(moment());
      resetAttendance();
      await getAttendance();
    }

    const emptyArray = arr => arr.length = 0;

    const getCellClass = (obj) => {
      if(moment(obj.column.label).isSame(moment().clone().startOf('day'), 'd')){
        return 'dateToday';
      }
    };

    const attend_statuses = ['Present','Late','Early'];
    const changeAttendType = (index, row, date, propName) => {
      let currentStatus = row[propName].status;
      let id = row[propName].id;
      let newStatus = '';
      switch(currentStatus) {
        case 'Select':
          newStatus = 'Present';
          break;
        case 'Present':
          newStatus = 'Late';
          break;
        case 'Late':
          newStatus = 'Absent';
          break;
        case 'Absent':
          newStatus = 'Early';
          break;
        case 'Early':
          newStatus = 'LOA';
          break;
        default:
          newStatus = 'Select';
      }

      if(id == null ){
        const formData : any = { subject_id : subjectId.value, student_id: row.student_id, attendance_date : date, attendance_status : newStatus };
        ApiService.setHeader();
        ApiService.post("attendance", formData)
            .then((data) => {
                  row[propName].status = newStatus;
                  row[propName].id = data.data.data.id;
            })
            .catch(({ response }) => {
                console.log('create attendance error => '+response.data.errors);
            });
      }
      else{
        const formData : any = { attendance_status : newStatus, student_id: row.student_id, subject_id:subjectId.value };
        ApiService.setHeader();
        ApiService.update("attendance", id, formData)
            .then(() => {
                  row[propName].status = newStatus;
            })
            .catch(({ response }) => {
                console.log('update attendance error => '+response.data.errors);
            });
      }
    };

    const openUpdateModal = (date) =>{
        targetDate.value = date;
        let updateButton = document.getElementById('update_attendance');
        updateButton?.click();
    }

    const getSemesters = () : any => {
        return new Promise((resolve, reject) => {  
            ApiService.setHeader();
            ApiService.get("class/semesters")
                .then(({ data }) => {
                    let sortedData = data.data.sort(function(a,b) { 
                          if(a.id == b.id) return 0;

                          if (Date.parse(a.start_date!) < Date.parse(b.start_date!))
                              return 1;
                          if (Date.parse(a.start_date!) > Date.parse(b.start_date!))
                              return -1;
                          return 0;
                      });
                    semesters.value = sortedData;
                    resolve(sortedData);
                })
                .catch((error) => {
                    reject(new Error ('getSemesters error => '+error));
                });
        });
    }

    const updateClasses = async() => {
      await getSubjects().then(async()=>{
          await changeSubject();
      });
    }

    
    const getAttendanceButtonClass = (row, propName) : string =>{
      if(Object.keys(row).length > 0){
        let status = row[propName].status;
        if(status == "Present"){
          return "bg-light-success text-success";
        }
        if(status == "Absent"){
          return "bg-light-danger text-danger";
        }
      }

      return "";
    }

    onBeforeMount(async() => {
      getSemesters().then(async (data) => {
        if(data){
          let today = new Date();
          let currentSemester = data.filter(semester => (semester.start_date <= today && semester.end_date >= today));

          if(currentSemester.length > 0){
            semesterId.value = currentSemester[0].id;
          }
          else{
            semesterId.value = data[0].id;
          }

          await getSubjects().then(async()=>{
            await setCurrentDateRange();
            await changeSubject();
          });
        }
      });
    });

    onMounted(() => {
      setCurrentPageBreadcrumbs("Attendance", ["Academics"]);
    });

    return{
      subjects,
      subjectId,
      attendanceData,
      dateRange,
      changeAttendType,
      getCellClass,      
      setCurrentDateRange,
      setNextWeekDateRange,
      setPrevWeekDateRange,
      attendSearch,
      changeSubject,
      targetDate,
      openUpdateModal,
      students,
      getAttendance,
      classDays,
      semesters,
      semesterId,
      updateClasses,
      moment,
      attend_statuses,
      getAttendanceButtonClass
    }
  },
});
</script>

<style scoped>

  * >>> .dateToday {
    background:rgba(137, 211, 233,0.5);
    color:#3c3c3c;
  }

  * >>> .el-button--primary > i{
    color : white;
  }
</style>
